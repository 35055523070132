import {
    GET_UPLOAD_DATA
} from '../actions/UploadDocumentAction'

const initialState = []

const UploadDocumentReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_UPLOAD_DATA: {
            return [action.payload]
        }
        default: {
            return [...state]
        }
    }
}

export default UploadDocumentReducer
