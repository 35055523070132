import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

const StoreAlert = (props) => {
    const dispatch = useDispatch();
    const { messages } = useSelector((state) => state.messages);
    const message =  messages?.message;
    const type =  messages?.type;

    const onClose = async () => {
        await dispatch({
            type: 'MESSAGES',
            payload: {message: '', type: ''},
        });
    };

    return (
        <>
            {
                message && <Alert severity={type} onClose={onClose}>{message}</Alert>
            }
        </>
    )};

export default StoreAlert;
