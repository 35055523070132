import {
    GET_DOCUMENT_DATA
} from '../actions/GetDocumentsAction'
import {
    GET_REQUEST_BUSINESS_DOCUMENT_DATA
} from '../actions/GetRequestBusinessDocumentAction'
import {GET_REQUEST_PERSONAL_DOCUMENT_DATA} from "../actions/GetRequestPersonalDocumentAction";

const initialState = {}

const GetDocumentsReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_DOCUMENT_DATA: {
            return {...action.payload}
        }
        case GET_REQUEST_BUSINESS_DOCUMENT_DATA: {
            return {
                ...state,
                requestBusinessDocumentData: action.payload
            }
        }
        case GET_REQUEST_PERSONAL_DOCUMENT_DATA: {
            return {
                ...state,
                requestPersonalDocumentData: action.payload
            }
        }
        default: {
            return {...state}
        }
    }
}

export default GetDocumentsReducer
