import axios from 'axios'
import { URL_API } from '../../../config';

export const GET_REQUEST_BUSINESS_DOCUMENT_DATA = 'GET_REQUEST_BUSINESS_DOCUMENT_DATA'


const instance = axios.create({
  baseURL: `${URL_API}/api/`,
});

export const GetRequestBusinessDocumentAction = async () => (dispatch) => {
    const accessToken = localStorage.getItem('accessToken');
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return instance.get(`edge/get-request-business-documents`).then(async (res) => {
        const resData = await res;
        await dispatch({
            type: GET_REQUEST_BUSINESS_DOCUMENT_DATA,
            payload: resData.data,
        })
    })
}