import React, { useState, useEffect } from 'react'
import { Card, Grid } from '@material-ui/core'
import MUIDataTable from "mui-datatables";
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://zetodocupload.zetocloud.com/api/'
});

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#fff'
    },
    card: {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
}))

const FranchiseList = () => {
    const classes = useStyles()

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("auto");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [loading, setLoading] = useState(false);
    const [franchiseData, setFranchiseData] = useState([]);

    const columns = ["SBA FRANCHISE IDENTIFIER CODE", "BRAND", "MEETS FTC DEFINITION OF A FRANCHISE?", "IS AN  ADDENDUM NEEDED?", "SBA ADDENDUM - Form 2462", "SBA NEGOTIATED ADDENDUM", "SBA FRANCHISE IDENTIFIER CODE Start Date", "NOTES"];

    const options = {
        filter: false,
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        selectableRows: false,
        download: false,
        print: false,
        textLabels: {
            body: {
                noMatch: loading ? "Loading..." : "Sorry, no matching records found",
            },
        }
    };

    const loadDirectoryData = async () => {
        setLoading(true);
        await instance.get('https://api.zetocloud.com/expressloanteam/api/sba-franchise-directory-list').then(async (res) => {
            const resData = await res;
            let formatFranchiseList = [];
            if(resData.data.data){
                resData.data.data.map(data => {
                    formatFranchiseList.push([data.identifiercode, data.brand, data.ftc_definition, data.is_an_addendum_needed, data.sba_addendum_form_2462, data.sba_negotiated_addendum, data.sba_franchise_identifier_code_start_date, data.notes]);
                });
            }  
            setFranchiseData(formatFranchiseList);
        }).catch(e => {
            setLoading(false);
        });
        setLoading(false);
    }
    useEffect(() => {
        loadDirectoryData();
    }, []);

    const data = franchiseData;

    return (
        <>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div
                className={clsx(
                    classes.cardHolder
                )}
            >
                <Card>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className="p-8 h-full bg-light-gray relative">
                                <MUIDataTable
                                    title={"Newest SBA Approved Franchise List"}
                                    data={data}
                                    columns={columns}
                                    options={options}
                                    style={{padding: 0}}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </>
    )
}

export default FranchiseList
