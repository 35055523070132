import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { URL_API } from '../../config'
import { ZetoLoading } from 'app/components'
import { useDispatch } from 'react-redux';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    return true;
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'MESSAGES': {
            return {
                ...state,
                messages: action.payload,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const _dispatch = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        await axios.post(`${URL_API}/api/auth/login`, {
            email,
            password,
        }).then(response => {
            const { data } = response.data

            setSession(data.token)

            dispatch({
                type: 'LOGIN',
                payload: {
                    user: {...data, authRole: ['SA'] },
                },
            })
        }).catch(async res => {
            if(res && res.data && res.data.message){
                await _dispatch({
                    type: 'MESSAGES',
                    payload: {message: res.data.message[0], type: 'error'},
                });
            }
        });

    };

    const autoLogin = async (token) => {

        const response = await axios.post(`${URL_API}/api/auth/auto-login`, {
            token
        });
        const {data} = response.data

        setSession(data.token)

        dispatch({
            type: 'LOGIN',
            payload: {
                user: {...data, authRole: ['SA'] },
            },
        })
    };

    const register = async (email, username, password) => {
        const response = await axios.post(`${URL_API}/api/auth/register`, {
            email,
            username,
            password,
        })

        const { data } = response.data

        setSession(data.token)

        dispatch({
            type: 'REGISTER',
            payload: {
                user: data,
            },
        })
    }

    const logout = async () => {
        await axios.post(`${URL_API}/api/auth/logout`);
        setSession(null);
        dispatch({ type: 'LOGOUT' });
    };

    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    const response = await axios.get(`${URL_API}/api/auth/profile`)
                    const { data } = response.data;

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: data,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, []);

    if (!state.isInitialised) {
        return <ZetoLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                autoLogin,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
