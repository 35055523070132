import React from 'react'
import { ZetoLayouts } from './index'
import { ZetoSuspense } from 'app/components'
import useSettings from 'app/hooks/useSettings'

const ZetoLayout = (props) => {
    const { settings } = useSettings()
    const Layout = ZetoLayouts[settings.activeLayout]

    return (
        <ZetoSuspense>
            <Layout {...props} />
        </ZetoSuspense>
    )
}

export default ZetoLayout
