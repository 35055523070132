export const MESSAGES = 'MESSAGES';

const initialState = {};

const MessagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGES: {
            return {
                ...state,
                messages: action.payload,
            }
        }
        default:
            return {...state};
    }
};

export default MessagesReducer;
