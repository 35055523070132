import { combineReducers } from 'redux'
import UploadDocumentReducer from './UploadDocumentReducer'
import MessagesReducer from './MessagesReducer'
import GetDocumentsReducer from './GetDocumentsReducer'

const RootReducer = combineReducers({
    uploadDocument: UploadDocumentReducer,
    messages: MessagesReducer,
    getDocuments: GetDocumentsReducer,
})

export default RootReducer
