import axios from 'axios'
import { URL_API, OLIVER_API_URL } from "../../../config";

export const GET_UPLOAD_DATA = 'GET_UPLOAD_DATA'

const instance = axios.create({
  baseURL: URL_API+'/api/',
});

const makeFromData = (data) => {
    const formData = new FormData()

    for (const field in data) {
        formData.append(field, data[field])
    }
    return formData
}

export const UploadDocumentAction = async (data) => (dispatch) => {
    const accessToken = localStorage.getItem('accessToken');
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const formData = new FormData();
    formData.append('pdfdoc', data.pdfdoc);
    formData.append('edgeUserId', data.user.edge_user_id);
    formData.append('edgeUserEmail', data.user.email);
    formData.append('edgeUserName', data.user.name);
    formData.append('uniqueDocGroupId', data.uniqueDocGroupId);
    formData.append('totalUploadedDocs', data.totalUploadedDocs);

    return instance.post(URL_API+'/api/'+'analyze-document', formData);
}

export const DeleteNotRecognizedDocumentAction = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const formData = new FormData();
    formData.append('doc_data_id', data.docId);
    return instance.post(`${URL_API}/api/edge/delete-document`, formData);
};

export const DeleteRecognizedDocumentAction = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const formData = new FormData();
    formData.append('doc_request_id', data.docRequestId);
    return instance.post(`${URL_API}/api/edge/delete-document-request-id`, formData);
};
